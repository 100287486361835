//format-number
export const currencyFormat = (num) => (num == null || num === 0 ? '' : num.toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."));

export const roundNum = (num) => (Math.round(num * 100) / 100);

export const roundNumberCeil = (num) => (Math.ceil(num));

export const endPageListener = (handleScroll) => {
    window.addEventListener('scroll', handleScroll, {
        passive: true
    });
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
};

export const removeSpecialChar = (val) => {
    if (val) {
        val = val.trim();
        const cyrillicToLatin = {
            'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'ђ': 'đ',
            'е': 'e', 'ж': 'ž', 'з': 'z', 'и': 'i', 'ј': 'j', 'к': 'k',
            'л': 'l', 'љ': 'lj', 'м': 'm', 'н': 'n', 'њ': 'nj', 'о': 'o',
            'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'ћ': 'ć', 'у': 'u',
            'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'č', 'џ': 'dž', 'ш': 'š',
            'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Ђ': 'Đ',
            'Е': 'E', 'Ж': 'Ž', 'З': 'Z', 'И': 'I', 'Ј': 'J', 'К': 'K',
            'Л': 'L', 'Љ': 'Lj', 'М': 'M', 'Н': 'N', 'Њ': 'Nj', 'О': 'O',
            'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'Ћ': 'Ć', 'У': 'U',
            'Ф': 'F', 'Х': 'H', 'Ц': 'C', 'Ч': 'Č', 'Џ': 'Dž', 'Ш': 'Š'
        };

        const cyrillicRegex = /[\u0400-\u04FF]/;

        if (cyrillicRegex.test(val)) {
            val = val.replace(/./g, function (x) {
                return cyrillicToLatin[x] || x;
            });
        }

        const map = {
            "Ž": "Z",
            "ž": "z",
            "Đ": "DJ",
            "đ": "dj",
            "Š": "S",
            "š": "s",
            "Ć": "C",
            "ć": "c",
            "Č": "C",
            "č": "c",
        };

        // Zamena specifičnih karaktera u stringu val koristeći regularni izraz na osnovu mapiranja definisanog u objektu map
        let result = val.replace(/[ŽĐŠĆČžđščć]/g, function (match) {
            return map[match];
        });

        // Zamena svih karaktera koji nisu slova ili brojevi sa donjom crtom ("_") koristeći regularne izraze
        result = result.replace(/[^a-zA-Z0-9А-Яа-яЁё]/g, "_").replace(/_{2,}/g, '_');

        // Uklanjanje donje crte sa početka i kraja stringa
        if (result.endsWith('_')) {
            result = result.slice(0, -1);
        }

        return result;
    } else {
        return '';
    }
};

// funkcija za logovanje
export const customLogger = (type, message, object) => {
    const IS_DEVELOPMENT = false; // promeniti u false prilikom pusha
    if (IS_DEVELOPMENT) {
        switch (type) {
            case 'info':
                console.info(
                    `[${new Date().toLocaleString()}] [INFO] - ${message}`,
                    object ? `[OBJECT] - ${JSON.stringify(object)}` : '[NO_OBJECT] - No selected object'
                );
                break;
            case 'warn':
                console.warn(
                    `[${new Date().toLocaleString()}] [WARN] - ${message}`,
                    object ? `[OBJECT] - ${JSON.stringify(object)}` : '[NO_OBJECT] - No selected object'
                );
                break;
            case 'error':
                console.error(
                    `[${new Date().toLocaleString()}] [ERROR] - ${message}`,
                    object ? `[OBJECT] - ${JSON.stringify(object)}` : '[NO_OBJECT] - No selected object'
                );
                break;
            case 'trace':
                console.trace(
                    `[${new Date().toLocaleString()}] [TRACE] - ${message}`,
                    object ? `[OBJECT] - ${JSON.stringify(object)}` : '[NO_OBJECT] - No selected object'
                );
                break;
            default:
                console.log(
                    `[${new Date().toLocaleString()}] [LOGGER] - ${message},`,
                    object ? '[OBJECT] -' : '[NO_OBJECT] - No selected object', object && object
                );
        }
    }
};